import fetch from "auth/FetchInterceptor"
import { AUTH_TOKEN } from "constants/AuthConstant"
const ManagerService = {}

ManagerService.getManagers = function (page = 1) {
  return fetch({
    method: "get",
    url: `api/user/getAll?page=${page}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}
ManagerService.searchManagers = function (page, value) {
  let url = `api/user/getAll?page=${page}`
  if (value) {
    url = `api/user/getAll?page=${page}&fullName=${value}`
  }

  return fetch({
    method: "get",
    url: url,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}

ManagerService.getManagerById = function (id) {
  return fetch({
    method: "get",
    url: `api/user/getById/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}
ManagerService.deleteManager = function (id) {
  return fetch({
    method: "delete",
    url: `api/user/deleteById/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}
export default ManagerService
