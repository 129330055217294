import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit"
import ManagerService from "services/MangerService"
export const getAllManagers = createAsyncThunk(
  "managers/getAllManagers",
  async (page = 1, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setCurrentPage(page))
      const response = await ManagerService.getManagers(page)
      dispatch(clearStates())
      return response.data
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)

export const searchManagers = createAsyncThunk(
  "managers/searchMangers",
  async ({ page = 1, value }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setCurrentPage(page))
      const response = await ManagerService.searchManagers(page, value)
      dispatch(clearStates())

      return response.data
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)

export const deleteManager = createAsyncThunk(
  "managers/deleteManager",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setCurrentPage(1))
      const response = await ManagerService.deleteManager(id)
      dispatch(clearStates())
      await dispatch(getAllManagers())
      return id
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)

export const fetchOneManager = createAsyncThunk(
  "managers/fetchOneManager",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ManagerService.getManagerById(id)
      return response.data
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)
const initialState = {
  managers: [],
  manager: {},
  loading: false,
  error: null,
  totalItems: null,
  searchValue: null,
  searchCountry: null,
  currentPage: 1,
}

const ManagertSlice = createSlice({
  name: "managers",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true
    },
    hideLoading: (state, action) => {
      state.loading = false
    },
    hideError: (state, action) => {
      state.error = null
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload
    },

    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setSearchCountry: (state, action) => {
      state.searchCountry = action.payload
    },
    clearStates: (state) => {
      // state.currentPage = 1
      state.managers = []
      state.totalItems = null
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch clients
      .addCase(getAllManagers.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getAllManagers.fulfilled, (state, action) => {
        state.loading = false
        state.managers = action.payload.data
        state.totalItems = action.payload.totalItems
      })
      .addCase(getAllManagers.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(searchManagers.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(searchManagers.fulfilled, (state, action) => {
        state.loading = false
        state.managers = action.payload.data
        state.totalItems = action.payload.totalItems
      })
      .addCase(searchManagers.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })

      // Delete client
      .addCase(deleteManager.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(deleteManager.fulfilled, (state, action) => {
        const managerId = action.payload
        state.managers = state.managers.filter(
          (manager) => manager._id !== managerId
        )
        state.loading = false
        state.error = null
        state.totalItems = state.totalItems - 1
      })
      .addCase(deleteManager.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      //fetch one client
      .addCase(fetchOneManager.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchOneManager.fulfilled, (state, action) => {
        state.loading = false
        state.manager = action.payload
        state.error = null
      })
      .addCase(fetchOneManager.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      }) // validate client
  },
})
export default ManagertSlice.reducer
export const {
  showLoading,
  hideLoading,
  hideError,
  setCurrentPage,
  setSearchValue,
  setSearchCountry,
  clearStates,
} = ManagertSlice.actions
